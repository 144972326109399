import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"src/components/body/index.js\",\"import\":\"Krub\",\"arguments\":[{\"weight\":[\"400\",\"600\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"]}],\"variableName\":\"krub\"}");
;
import(/* webpackMode: "eager", webpackExports: ["LocalizationProvider","useLocalization"] */ "/vercel/path0/personly-libs/personly-libs-providers/src/localization.js");
;
import(/* webpackMode: "eager", webpackExports: ["NotificationsProvider","useNotifications"] */ "/vercel/path0/personly-libs/personly-libs-providers/src/notifications.js");
;
import(/* webpackMode: "eager", webpackExports: ["ResourcesProvider","useResources"] */ "/vercel/path0/personly-libs/personly-libs-providers/src/resources.js");
;
import(/* webpackMode: "eager", webpackExports: ["SchemesProvider","useSchemes"] */ "/vercel/path0/personly-libs/personly-libs-providers/src/schemes.js");
;
import(/* webpackMode: "eager", webpackExports: ["StreamsProvider","useStreams"] */ "/vercel/path0/personly-libs/personly-libs-providers/src/streams.js");
;
import(/* webpackMode: "eager", webpackExports: ["SuspensionsProvider","useSuspensions"] */ "/vercel/path0/personly-libs/personly-libs-providers/src/suspensions.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/button/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/card-button/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/checkbox/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/file-source-upload/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/file-source/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/icon-select/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/masonry/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/select/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/text-upload/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/textarea/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/time-since/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/toaster/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/toggle-description/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/toggle/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-web/personly-web-account/src/providers/errorCode.js");
;
import(/* webpackMode: "eager", webpackExports: ["LoginProvider","useLogin"] */ "/vercel/path0/personly-web/personly-web-account/src/providers/login.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-web/personly-web-account/src/providers/notifications.js");
;
import(/* webpackMode: "eager", webpackExports: ["PersonalStepsProvider","usePersonalSteps"] */ "/vercel/path0/personly-web/personly-web-account/src/providers/personalSteps.js");
;
import(/* webpackMode: "eager", webpackExports: ["RegisterProvider","useRegister"] */ "/vercel/path0/personly-web/personly-web-account/src/providers/register.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-web/personly-web-account/src/providers/schemes.js");
;
import(/* webpackMode: "eager", webpackExports: ["ValidatorsProvider","useValidators"] */ "/vercel/path0/personly-web/personly-web-account/src/providers/validators.js");
